<template>
  <div
    class="password container van-tab__panel"
    :style="{ backgroundImage: 'url(img/pro-bg.png)' }"
  >
    <div
      class="card shadow default border-r5 con mt-10"
      @click="changePwd('login')"
    >
      <van-icon name="lock" style="margin-left: 6px" />
      <span>{{$t('menus.password5')}}</span>
      <van-icon name="edit" style="font-size: 14px" />
    </div>
    <div
      class="card shadow default border-r5 con mt-10"
      @click="changePwd('safe')"
    >
      <van-icon name="card" style="margin-left: 6px" />
      <span>{{$t('menus.password4')}}</span>
      <van-icon name="edit" style="font-size: 14px" />
    </div>
  </div>
</template>

<script setup>
// import { ref, onMounted, watchEffect, nextTick } from 'vue'
// import { Toast } from 'vant'
import { useRouter } from "vue-router";
const router = useRouter();
const changePwd = (type) => {
  router.push({ path: "/pages/passwordChange", query: { type: type } });
};
</script>

<style lang="less" scoped>
.password {
  background-repeat: no-repeat;
  background-size: 80% auto;
  background-position: 0 -50px;
  color: #ddd;

  .card {
    display: flex;
    justify-content: left;
    /* 水平居中 */
    align-items: center;
    justify-content: space-between;
    font-size: 18px;

    i:first-child {
      font-size: 40px;
      margin-right: 20px;
    }

    span {
      width: 80%;
    }
  }
}
</style>
